import moment from 'moment';
import 'moment/dist/locale/es';
import React, { useCallback, useMemo } from 'react';
import { Calendar, EventPropGetter, momentLocalizer } from 'react-big-calendar';
import { IAppointment } from '../../types/models/Appointment';
import { useNavigation } from '../auth/NavigationContext';

moment.locale('es'); // Set moment's locale to Spanish
const localizer = momentLocalizer(moment);

const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  noEventsInRange: 'No hay eventos en este rango.',
};
type Props = {
  onRangeChange: any
  events: any[];
  onSelectSlot: any
  onSelectEvent: any
}
export const AppointmentCalendar: React.FC<Props> = ({ events, onSelectSlot, onSelectEvent, onRangeChange }) => {
  const { doctor } = useNavigation();
  const handleRangeChange = (p: any) => {
    let start, end
    if (Array.isArray(p)) {
      start = p[0]
      end = p[p.length - 1]

    }
    else {
      start = p.start
      end = p.end

    }
    start = moment(start).format('YYYY-MM-DD')
    end = moment(end).format('YYYY-MM-DD')
    console.log(start, "-", end)
    onRangeChange(start, end)
  }
  const eventPropGetter: EventPropGetter<IAppointment> = useCallback((event, start, end, isSelected) => {
    console.log(event)
    return {
      ...(event.status === 2 && {

        className: 'bg-success'
      }),
      ...(event.status === 1 && {

        className: 'bg-danger'
      }),
    }
  },
    []
  );
  const defaultDate = useMemo(() => new Date(), []);

  return (
    <div style={{ height: 800 }}>

      <Calendar
        onSelectSlot={onSelectSlot}
        localizer={localizer}
        culture="es"
        selectable
        events={events.map(x => ({ ...x, start: moment(x.start).toDate() }))}
        onSelectEvent={onSelectEvent}
        startAccessor='start'
        endAccessor="end"
        defaultView='week'
        messages={messages}
        onRangeChange={handleRangeChange}
        min={moment(doctor?.entry_time, "HH:mm").toDate()}
        max={moment(doctor?.exit_time, "HH:mm").toDate()}
        eventPropGetter={eventPropGetter}
        formats={{
          weekdayFormat: (date, culture, localizer) =>
            localizer!.format(date, 'dd', culture).charAt(0).toUpperCase(), // Single-letter weekday abbreviation
          timeGutterFormat: 'h A', // 24-hour clock in the time column
          agendaTimeRangeFormat: ({ start, end }, culture, localizer) => localizer!.format(start, 'hh:mm A', culture) + ' - ' + localizer!.format(end, 'hh:mm A', culture),
          eventTimeRangeFormat: ({ start, end }, culture, localizer) => localizer!.format(start, 'hh:mm A', culture) + ' - ' + localizer!.format(end, 'hh:mm A', culture),


        }}

        defaultDate={defaultDate}
      />
    </div>
  );
};

